import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  allRoles = ["System Administrator", "Corporate", "Region Manager", "Area Manager", "District Manager", "Dealer", "Dealer Group", "Agency", "System Analyst"];
  allRolesExceptAgency = ["System Administrator", "Corporate", "Region Manager", "Area Manager", "District Manager", "Dealer", "Dealer Group", "System Analyst"];

    public routes: IRouteConfig[] = [
      {
        id: 'website',
        category: 'website',
        default: true,
        defaultRoute: '/website/website-overview',
        displayName: 'Website',
        displayInNav: true,
        iconClass: 'fa fa-globe',
        roles: this.allRolesExceptAgency,
        children: [
          {
            parent: '/website',
            route: 'website-overview',
            displayName: 'Overview',
            pdfEnabled: false,
            breadcrumbHelpEnabled: true,
            iconClass: 'fa fa-users',
            roles: undefined
          }
         ]
      },
      {
        id: 'digAd',
        category: 'digAd',
        default: true,
        defaultRoute: '/digAd/dashboard',
        displayName: 'Digital Advertising',
        displayInNav: true,
        iconClass: 'fa fa-desktop',
        roles: this.allRoles,
        children: [
          {
            parent: '/digAd',
            route: 'dashboard',
            displayName: 'Digital Advertising Overview',
            pdfEnabled: false,
            iconClass: 'fa fa-users',
            breadcrumbHelpEnabled: true,
            roles: this.allRolesExceptAgency
          }
        ]
      },
      {
        id: 'provider-tools',
        category: 'provider-tools',
        default: true,
        defaultRoute: '/provider-tools/chat-overview',
        displayName: 'Provider Tools',
        displayInNav: true,
        iconClass: 'fa fa-building',
        roles: this.allRolesExceptAgency,
        children: [
            {
                parent: '/provider-tools',
                route: 'chat-overview',
                displayName: 'Chat Overview',
                pdfEnabled: false,
                breadcrumbHelpEnabled: true,
                iconClass: 'fa fa-users',
                roles: this.allRolesExceptAgency
            }
        ]
      },
      {
        id: 'management',
        category: 'management',
        defaultRoute: '/management/users',
        displayName: 'Management',
        displayInNav: true,
        iconClass: 'fa fa-cog',
        roles: ['System Administrator', 'System Analyst'],
        children: [
          {
            parent: '/management',
            route: 'resource-string',
            displayName: 'Resource Strings',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', 'System Analyst', "Corporate", "Dealer"],
            children:
            [
            ]
          },
          {
            parent: '/management',
            route: 'provider',
            displayName: 'Providers',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', 'System Analyst', "Corporate", "Dealer"],
            children:
            [
              {
                id: 'provider/create',
                parent: 'provider',
                route: 'create',
                displayName: 'Create Provider',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'provider/update',
                parent: 'provider',
                route: 'update/:id',
                displayName: 'Edit Provider',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              }
            ]
          },
          {
            parent: '/management',
            route: 'content',
            displayName: 'Content',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', 'System Analyst']
          }, {
            parent: '/management',
            route: 'report-views-configuration',
            displayName: 'Report Views',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', 'System Analyst'],
            children:
            [
              {
                id: 'report-views-configuration/create',
                parent: 'report-views-configuration',
                route: 'create',
                displayName: 'Create Report View',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'report-views-configuration/create',
                parent: 'report-views-configuration',
                route: 'create/:id',
                displayName: 'Create Report View',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'report-views-configuration/update',
                parent: 'report-views-configuration',
                route: 'update/:id',
                displayName: 'Edit Report View',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
            ]
          }, {
            parent: '/management',
            route: 'data-sets-configuration',
            displayName: 'Data Sets',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', 'System Analyst'],
            children:
            [
              {
                id: 'data-sets-configuration/create',
                parent: 'data-sets-configuration',
                route: 'create',
                displayName: 'Create Data Set',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'data-sets-configuration/create',
                parent: 'data-sets-configuration',
                route: 'create/:id',
                displayName: 'Create Data Set',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'data-sets-configuration/create',
                parent: 'data-sets-configuration',
                route: 'create/project/:queryConfigId',
                displayName: 'Create Data Set',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'data-sets-configuration/update',
                parent: 'data-sets-configuration',
                route: 'update/:id',
                displayName: 'Edit Data Set',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
            ]
          }, {
            parent: '/management',
            route: 'query-configuration',
            displayName: 'Queries',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', 'System Analyst'],
            children:
            [
              {
                id: 'query-configuration/create',
                parent: 'query-configuration',
                route: 'create',
                displayName: 'Create Query',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'query-configuration/create',
                parent: 'query-configuration',
                route: 'create/:id',
                displayName: 'Create Query',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'query-configuration/update',
                parent: 'query-configuration',
                route: 'update/:id',
                displayName: 'Edit Query',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'query-configuration/test',
                parent: 'query-configuration',
                route: 'test/:id',
                displayName: 'Test Query',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
            ]
          },
          {
            parent: '/management',
            route: 'users',
            displayName: 'Users',
            iconClass: 'fa fa-users',
            roles: ['System Administrator', 'System Analyst']
            },
            {
                parent: '/management',
                route: 'settings',
                displayName: 'Settings',
                iconClass: 'fa fa-code',
                roles: ['System Administrator']
            }
        ]
      }
    ];
}
